<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="700" scrollable>
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>
            <span>{{ title }} category</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field outlined dense v-model="category.title">
                  <template v-slot:label>
                    Title <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.category.title.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="text-body">Description</div>
                <ckeditor :config="editorConfig"
                    v-model="category.description">
                </ckeditor>
              </v-col>
              <v-col cols="6" md="4">
                Status
                <v-switch
                    v-model="category.is_active"
                    :label="category.is_active?'Active':'Inactive'"
                    color="primary">
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard cancel-btn"
              depressed

              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              class="text-white ml-2 btn btn-primary"
              depressed
              :loading="loading"
              @click="createOrUpdate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import PredefinedResponseCategoryService from "@/core/services/predefined-response/PredefinedResponseCategoryService";
import {required, email, requiredIf} from "vuelidate/lib/validators";
const predefinedResponseCategory=new PredefinedResponseCategoryService;
export default {
  validations: {
    category: {
      title:{required},

    }
  },
  data(){
    return{
      dialog:false,
      edit:false,
      title:null,
      category:{
        title:null,
        description:null,
        is_active:1,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ]
      },
      loading:false,
    }
  },
  methods:{
    openDialog() {
      this.dialog=true;
    },
    closeDialog() {
      this.dialog=false;
      this.resetDialog();
      this.$v.$reset();
    },
    resetDialog(){
      this.edit=false;
      this.category={
        title:null,
        description:null,
        is_active:1,
      }
    },
    createCategory() {
      this.openDialog();
      this.title='Create';
      this.edit=false;
      this.resetDialog();
    },
    editCategory(item) {
      this.openDialog();
      this.title='Edit';
      this.edit=true;
      this.category=item;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create(this.category);
        else this.update(this.category);
      }
    },
    _create: function (data) {
      this.loading = true;
      predefinedResponseCategory
          .create(data)
          .then(response => {
            this.$snotify.success("Category added");

            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function (data) {
      this.loading = true;
      predefinedResponseCategory
          .update(this.category.id, data)
          .then(response => {
            this.$snotify.success("Category updated");
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;

            this.$snotify.error("Oops something went wrong");
          });
    },
  }
}
</script>
